import React from 'react';
import { arrayOf, oneOf, string, shape } from 'prop-types';
import { Script } from 'nordic/script';

/* istanbul ignore next */
const ScriptTracks = ({ url, event_tracking, verification_resources, nonce, itemClassName, hydrate }) => {
  const [firstDelayedPrintUrl] = event_tracking?.delayed_print ?? [];
  const [firstViewUrl] = event_tracking?.view ?? [];
  const delayedPrint = firstDelayedPrintUrl ? firstDelayedPrintUrl.url : '';

  return (
    <Script>
      {`var trackMclicks = document.createElement('img');
        trackMclicks.src = '${url}';
        trackMclicks.nonce = '${nonce}';
        trackMclicks.style.opacity='0.0';
        trackMclicks.style.visibility='hidden';
        trackMclicks.style.position='absolute';
        trackMclicks.style.zIndex='-1';
        trackMclicks.classList.add("ads-carousel-pixel");
        document.body.appendChild(trackMclicks);

        if (${hydrate === 'defer'}) {
          var firstViewUrl = ${JSON.stringify(firstViewUrl)};
          var delayedPrint = '${delayedPrint}';
          var verificationResources = ${JSON.stringify(verification_resources)};
          var carouselItemRef = document.querySelector('.${itemClassName}');
            
          function startViewabilitySession() {
            if (window.viewability && carouselItemRef && firstViewUrl?.url) {
              var session = window.viewability.addSession(verificationResources, delayedPrint);
              session.setMeliVaTrackUrl(firstViewUrl.url);
              session.start(carouselItemRef);
            }
          };

          document.getElementById('viewability-script').addEventListener('load', function() {
            if (window.viewability) {
              startViewabilitySession();
            }

            if (window.viewability && window.viewability.version !== '1.0.0') {
              window.viewability.addSession(carouselItemRef, firstViewUrl.url, verificationResources, delayedPrint);
            }
          });
        }
      `}
    </Script>
  );
};

ScriptTracks.propTypes = {
  url: string,
  event_tracking: shape({
    print: arrayOf(
      shape({
        url: string,
      }),
    ),
    view: arrayOf(
      shape({
        url: string,
      }),
    ),
  }),
  verification_resources: arrayOf(shape({})),
  nonce: string,
  itemClassName: string,
  hydrate: oneOf(['defer', 'now']),
};

export default ScriptTracks;
